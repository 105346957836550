import {Pages} from "./Pages";
import "jquery-validation";
import "jquery-validation/dist/additional-methods";
import "jquery-validation/dist/localization/messages_ru";
import {Utils} from "../Utils";

export class ResetPassword extends Pages {

    initPageHandlers(params) {
        super.initPageHandlers(params);

        let pasBase = document.getElementById('password'),
            pasRep = document.getElementById('password_repeat'),
            switchers = document.querySelectorAll("[data-element-type='switcher']"),
            submit = document.getElementById('reset_password_submit'),
            form = document.getElementById('auth_form'),
            faEye = '<i class="fa-regular fa-eye"></i>',
            faEyeSlash = '<i class="fa-regular fa-eye-slash"></i>';

        function showPassword(event) {
            let clicked = event.currentTarget,
                fieldId = clicked?.dataset.relativeElementId,
                field = document.getElementById(fieldId);
            if (field.type === "password") {
                field.type = "text";
                clicked.innerHTML = faEyeSlash;
            } else {
                field.type = "password";
                clicked.innerHTML = faEye;
            }
        }

        //проверка сложности пароля
        pasBase.addEventListener("blur", (event) => {
            let passwordField = event.target,
                password = passwordField.value;
            if (password.length > 0 && Utils.checkPasswordDifficult(password) < 4) {
                alert("Пароль слишком простой!");
                passwordField.value = "";
                passwordField.focus();
                return false;
            }
        });

        //проверка повтора пароля
        pasRep.addEventListener("blur", function () {
            if (pasBase.value !== "" && pasRep.value !== "" && pasRep.value !== pasBase.value) {
                pasRep.value = "";
                pasRep.focus();
                alert("Пароли не совпадают!");
            }
        });

        // покажем пароль
        switchers.forEach(switcher => {
            switcher.addEventListener('click', showPassword);
            if ('ontouchend' in window) {
                switcher.addEventListener("touchend", showPassword);
            }
        });

        //валидация (совсем небольшая)
        form.addEventListener("submit", (event) => {
            event.preventDefault();

            let errorMessages = [];

            if (pasBase.value === "") {
                errorMessages.push("Поле пароль должно быть заполнено!");
            }

            if (pasBase.value !== "" && pasRep.value === "") {
                errorMessages.push("Пароли не совпадают!");
            }

            if (errorMessages.length > 0) {
                errorMessages.push("\r\nФорма не может быть отправлена!");
                alert(errorMessages.join("\r\n"));
                return false;
            }

            form.submit();

        });
    }
}
